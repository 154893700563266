<template>
  <section id="community-connections" class="col-sm-12">
    <div class="d-md-flex justify-content-between mb-3 flex-column flex-md-row">
      <div class="search-container">
        <b-input-group class="mt-3">
          <b-input-group-append>
            <b-button> <i class="fas fa-search"></i> </b-button>
          </b-input-group-append>
          <b-form-input
            v-model="searchTerm"
            type="text"
            :placeholder="$t('labels.frontend.community_connections.search')"
            class="form-control"
            aria-label="Search"
          ></b-form-input>
          <b-input-group-append> </b-input-group-append>
        </b-input-group>
      </div>
      <div class="dropdown-container">
        <h5 class="filter-header">
          {{ $t('labels.frontend.community_connections.filter_by') }}:
        </h5>
        <div class="dropdown-innerContainer">
          <button
            class="customDropdown"
            id="dropdown-filter"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <span class="dropdown-text">{{
              selectedAgency
                ? selectedAgency.title
                : $t('labels.frontend.community_connections.agency')
            }}</span>
            <span class="dropdown-arrow">&#9662;</span>
          </button>
          <div
            class="dropdown-menu dropdown-menu_custom"
            aria-labelledby="dropdown-filter"
          >
            <div class="dropdown-item" @click="selectAgency(null)">
              {{ $t('labels.frontend.community_connections.view_all') }}
            </div>
            <div
              class="dropdown-item"
              v-for="agency in agencyList"
              :key="agency.id"
              @click="selectAgency(agency)"
            >
              {{ agency.title }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="filteredCommunityPrograms.length > 0">
      <b-card
        v-for="(data, index) in filteredCommunityPrograms"
        :key="index"
        class="mb-4 custom-card"
      >
        <b-row class="text-center">
          <b-col cols="12">
            <h5 class="font-weight-bold">
              {{ data.program_name[$i18n.locale] }}
            </h5>
          </b-col>
        </b-row>
        <b-row class="text-center">
          <b-col cols="12">
            <p>
              {{ $t('labels.frontend.community_connections.by') }}
              {{ getAgencyById(data.agency_id) }}
            </p>
          </b-col>
        </b-row>
        <br />
        <b-row>
          <b-col cols="12">
            <p v-html="data.program_description[$i18n.locale]"></p>
          </b-col>
        </b-row>
      </b-card>
    </div>
    <div v-else>
      <p class="text-muted text-center">
        {{ $t('labels.frontend.community_connections.no_programs_found') }}.
      </p>
    </div>
  </section>
</template>
<script>
import axios from 'axios'
export default {
  name: 'CommunityConnections',
  props: {
    programs: {
      type: Array,
      default: () => []
    },
    agencies: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      communityPrograms: this.programs,
      agencyList: this.agencies,
      searchTerm: '',
      selectedAgencyId: null,
      selectedAgency: null
    }
  },
  computed: {
    filteredCommunityPrograms() {
      const searchTerm = this.searchTerm.toLowerCase()

      return this.communityPrograms.filter(data => {
        const programName = data.program_name[this.$i18n.locale].toLowerCase()
        const agencyName = this.getAgencyById(data.agency_id).toLowerCase()
        const programDescription = data.program_description[
          this.$i18n.locale
        ].toLowerCase()

        const isMatchingSearchTerm =
          programName.includes(searchTerm) ||
          agencyName.includes(searchTerm) ||
          programDescription.includes(searchTerm)

        const isMatchingAgency =
          !this.selectedAgency || data.agency_id === this.selectedAgency.id

        return isMatchingSearchTerm && isMatchingAgency
      })
    }
  },
  async mounted() {
    try {
    } catch (e) {
      this.$app.error(e)
      return []
    }
  },
  methods: {

    getAgencyById(id) {
      if (id != 0) {
        return this.agencyList.filter(serviceItem => {
          return serviceItem.id == id
        })[0]?.title
      } else {
        return ''
      }
    },
    selectAgency(agency) {
      this.selectedAgency = agency
    }
  }
}
</script>
<style scoped>
.custom-card {
  border: 1px solid #dee2e6;
  border-radius: 10px;
}

.custom-card .text-center:not(:last-child) {
  display: flex;
  justify-content: center;
}

h5.filter-header {
  font-size: 18px;
  font-weight: 700;
  color: #000000;
}

.dropdown-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
}

.dropdown-innerContainer {
  white-space: nowrap;
  border-radius: 3px;
  border: 2px solid #ff0000;
  color: #333333;
}

.dropdown-container {
  display: flex;
  align-items: center;
  gap: 20px;
}

.dropdown-innerContainer {
  white-space: nowrap;
  border-radius: 3px;
  border: 2px solid #ff0000;
  color: #333333;
  position: relative;
}

.customDropdown {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 5px 10px;
  color: inherit;
  font-weight: bold;
  text-decoration: none;
  display: flex;
  align-items: center;
}

.customDropdown:hover .dropdown-text {
  text-decoration: underline;
}

.dropdown-arrow {
  margin-left: 5px;
}

.dropdown-menu {
  display: none;
  position: absolute;
  background-color: white;
  border: 1px solid #ccc;
  width: 480px;
  left: auto;
  right: 0;
  top: 100%;
  min-width: unset;
  overflow: initial;
}

.dropdown-innerContainer:hover .dropdown-menu {
  display: block;
}

.dropdown-menu .dropdown-item {
  padding: 8px;
  cursor: pointer;
  transition: background-color 0.3s;
  overflow: initial;
  max-width: 100%;
}

.dropdown-item:hover {
  background-color: #f0f0f0;
}

@media (max-width: 767.98px) {
  .search-container,
  .dropdown-container {
    margin-bottom: 15px;
  }
}
</style>
