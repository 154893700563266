import { render, staticRenderFns } from "./CommunityConnections.vue?vue&type=template&id=3e66a78b&scoped=true&"
import script from "./CommunityConnections.vue?vue&type=script&lang=js&"
export * from "./CommunityConnections.vue?vue&type=script&lang=js&"
import style0 from "./CommunityConnections.vue?vue&type=style&index=0&id=3e66a78b&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3e66a78b",
  null
  
)

component.options.__file = "CommunityConnections.vue"
export default component.exports