import 'babel-polyfill'
import loadClientScripts from './load-client-scripts'
import 'bootstrap-material-design/js/'
// Vue & axios
import 'babel-polyfill'
import Vue from 'vue'
import { axios } from '../axios-config'

import BootstrapVue from 'bootstrap-vue/dist/bootstrap-vue.esm'

import { createLocales } from '../vue-i18n-config'
import Noty from 'noty'
//Components
import signInForm from './components/SignInForm'
import signInFormConnect from './components/SignInFormConnect'
import signUpForm from './components/SignUpForm'
import signUpFormConnect from './components/SignUpFormConnect'
import forgotPassForm from './components/ForgotPassForm'
import RegisterToAgency from './components/RegisterToAgency'
import SendEmailForm from './components/SendEmailForm'
import AgencyServiceCategories from './components/AgencyServiceCategories'
import DropdownWithCheckboxes from './components/DropdownWithCheckboxes'
import DropdownFilter from './components/DropdownFilter'
import LasiCoalitionAgencies from './components/LasiCoalitionAgencies'
import CommunityServices from './components/CommunityServices'
import CommunityConnections from './components/CommunityConnections'
import ServiceProviders from "./components/ServiceProviders";
import ServiceProvider from "./components/ServiceProvider";
import DashboardLink from "./components/DashboardLink";
import vSelect from "vue-select";
import PasswordMeter from "vue-simple-password-meter";
import VuePassword from 'vue-password';

window.axios = axios

Vue.component('v-select', vSelect)

// Bootstrap Vue
Vue.use(BootstrapVue)
export function createApp() {
  const i18n = createLocales(window.locale)

  // Components
  Vue.component('sign-in-form', signInForm)
  Vue.component('sign-in-form-connect', signInFormConnect)
  Vue.component('sign-up-form', signUpForm)
  Vue.component('sign-up-form-connect', signUpFormConnect)
  Vue.component('forgot-pass-form', forgotPassForm)
  Vue.component('register-to-agency', RegisterToAgency)
  Vue.component('send-email-form', SendEmailForm)
  Vue.component('agency-service-categories', AgencyServiceCategories)
  Vue.component('dropdown-with-checkboxes', DropdownWithCheckboxes)
  Vue.component('dropdown-filter', DropdownFilter)
  Vue.component('lasi-coalition-agencies', LasiCoalitionAgencies)
  Vue.component('community-services', CommunityServices)
  Vue.component('community-connections', CommunityConnections)
  Vue.component('service-providers', ServiceProviders)
  Vue.component('service-provider', ServiceProvider)
  Vue.component('DashboardLink', DashboardLink )
  Vue.component('password-meter', PasswordMeter)
  Vue.component('VuePassword', VuePassword)

  Vue.prototype.$app = window.settings

  if (Vue.prototype.$app.user) {
    Vue.prototype.$app.user.can = permission => {
      if (Vue.prototype.$app.user.id === 1) {
        return true
      }
      return Vue.prototype.$app.permissions.includes(permission)
    }
    Vue.prototype.$app.user.hasAccess = permission => {
      if (Vue.prototype.$app.user.id === 1) {
        return true
      }
      return Vue.prototype.$app.accessControl.includes(permission)
    }

    Vue.prototype.$app.user.hasRole = role => {
      return Vue.prototype.$app.user.roles.map(role => role.name).includes(role)
    }
  }

  /**
   * Object to FormData converter
   */
  let objectToFormData = (obj, form, namespace) => {
    let fd = form || new FormData()

    for (let property in obj) {
      if (!obj.hasOwnProperty(property)) {
        continue
      }

      let formKey = namespace ? `${namespace}[${property}]` : property

      if (obj[property] === null) {
        fd.append(formKey, '')
        continue
      }
      if (typeof obj[property] === 'boolean') {
        fd.append(formKey, obj[property] ? '1' : '0')
        continue
      }
      if (obj[property] instanceof Date) {
        fd.append(formKey, obj[property].toISOString())
        continue
      }
      if (
        typeof obj[property] === 'object' &&
        !(obj[property] instanceof File)
      ) {
        objectToFormData(obj[property], fd, formKey)
        continue
      }
      fd.append(formKey, obj[property])
    }

    return fd
  }

  Vue.prototype.$app.objectToFormData = objectToFormData

  /**
   * Notifications
   */

  let noty = (type, text) => {
    new Noty({
      layout: 'topRight',
      theme: 'bootstrap-v4',
      timeout: 2000,
      text,
      type
    }).show()
  }

  Vue.prototype.$app.noty = {
    alert: text => {
      noty('alert', text)
    },
    success: text => {
      noty('success', text)
    },
    error: text => {
      noty('error', text)
    },
    warning: text => {
      noty('warning', text)
    },
    info: text => {
      noty('info', text)
    }
  }

  Vue.prototype.$app.error = error => {
    if (error instanceof String) {
      noty('error', error)
      return
    }

    if (error.response) {
      // Not allowed error
      if (error.response.status === 403) {
        noty('error', i18n.t('exceptions.unauthorized'))
        return
      }

      // Domain error
      if (error.response.data.error !== undefined) {
        noty('error', error.response.data.message)
        return
      }
    }

    // Generic error
    noty('error', i18n.t('exceptions.general'))
  }

  const app = new Vue({
    i18n
  })

  return { app }
}

// Load Client Scripts
loadClientScripts(createApp)
